import React, { useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { UserContext } from '../../context/context';

// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  NavbarToggler,
} from "reactstrap";

function AdminNavbar(props) {
  // hooks
  const { removeUser, user } = useContext(UserContext);

  //states
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };

  const logout = () => {
    removeUser();
  }
  return (
    <>
      <Navbar className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <NavbarBrand href="/" onClick={(e) => e.preventDefault()}>
              <h3 style={{margin:"auto"}}>Dashboard</h3>
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <p style={{margin: '0.5rem', padding: '0.5rem', color: '#00ECC6', textAlign: 'center', border: '1px solid #00ECC6', borderRadius: '5px'}}>{user.email}</p>
                <Button onClick={() => logout()} style={{display: 'flex', justifyContent: 'center', width: '75%', margin: 'auto'}}>
                Logout
              </Button>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      
    </>
  );
}

export default AdminNavbar;
