import React, { useContext } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import { UserContext } from '../../context/context';
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from 'routes.js';

import logo from 'assets/img/react-logo.png';
import { BackgroundColorContext } from 'contexts/BackgroundColorContext';

var ps;

function User(props) {
  //hooks
  const { user } = useContext(UserContext);

  const array = routes.filter(prop => prop.layout === '/user');
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf('nav-open') !== -1
  );

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/user') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  if (user.role === '0') {
    return (
      <BackgroundColorContext.Consumer>
        {({ color, changeColor }) => (
          <React.Fragment>
            <div className="wrapper">
              <Sidebar
                routes={array}
                logo={{
                  outterLink: '',
                  text: 'Ace Cloud',
                  imgSrc: logo,
                }}
                toggleSidebar={toggleSidebar}
              />
              <div className="main-panel" ref={mainPanelRef} data={color}>
                <AdminNavbar
                  brandText={getBrandText(location.pathname)}
                  toggleSidebar={toggleSidebar}
                  sidebarOpened={sidebarOpened}
                />
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="*" to="user/devices" />
                </Switch>
                {
                  // we don't want the Footer to be rendered on map page
                  location.pathname === '/user/maps' ? null : <Footer fluid />
                }
              </div>
            </div>
            {/* <FixedPlugin bgColor={color} handleBgClick={changeColor} /> */}
          </React.Fragment>
        )}
      </BackgroundColorContext.Consumer>
    );
  } else {
    return <Redirect to="/login" />;
  }
}

export default User;
