import React, { useState } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner'
import { isEmpty } from 'lodash';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { SENSORS } from '../constants';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Alert
} from "reactstrap";

function UpdateDevices() {
  // State
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: false,
    updateMsg: false,
    error: null
  });
  const [newDevices, setNewDevices] = useState([]);
  const [remDevices, setRemDevices] = useState([]);
  const [sensorsData, setSensorsData] = useState([]);
  const [currSensorsData, setCurrSensorsData] = useState([]);

  // add new devices to state
  function addNewItem(params) {
    let deviceName = document.getElementById("newDeviceName");
    let deviceId = document.getElementById("newDeviceId");

      if (deviceName.value !== "" && deviceId.value !== "" && !isEmpty(currSensorsData)) {
        const newItem = deviceName.value.trim() + ":" + deviceId.value.trim();
        const list = [...newDevices];
        list.push(newItem);

        setSensorsData([...sensorsData, { device: newItem, arr: currSensorsData }]);
        setNewDevices(list);
      } else {
        setLoadingAndMsg({
          loading: false,
          signUpMsg: false,
          error: "Please Enter Correct Device Name, ID and Sensor to be Added!",
        });
      }
      deviceName.value = "";
      deviceId.value = "";
      setCurrSensorsData([]);
   
  }


  function handleSensor(sensor) {
    if (!currSensorsData.includes(sensor)) {
      setCurrSensorsData([...currSensorsData, sensor]);
    }
  }
  function handleRemoveSensor(sensor) {
    const temp = currSensorsData.filter(e => e !== sensor);
    setCurrSensorsData(temp);
  }

  // remove new devices from state
  function deleteNewItem(i) {
    let deviceDel = document.getElementById("device-" + i).innerText;
    let list = [...newDevices];
    let sensorList = [...sensorsData];

    list = list.filter(d => d !== deviceDel);
    sensorList = sensorList.filter(e => e.device !== deviceDel);
    setSensorsData(sensorList);
    setNewDevices(list);
  }

  // add devices to be removed to state
  function addRemItem() {
    let deviceName = document.getElementById("remDeviceName");
    let deviceId = document.getElementById("remDeviceId");

    if (deviceName.value !== "" && deviceId.value !== "") {
      const newItem = deviceName.value.trim() + ":" + deviceId.value.trim();
      const list = [...remDevices];
      list.push(newItem);
      setRemDevices(list);
    } else {
      setLoadingAndMsg({
        loading: false,
        signUpMsg: false,
        error: "Please Enter Device Name and ID!",
      });
    }
    deviceName.value = "";
    deviceId.value = "";
  }
  // remove the devices to be removed from state
  function deleteRemItem(i) {
    let deviceDel = document.getElementById("device-" + i).innerText;
    let list = [...remDevices];
    let sensorList = [...sensorsData];

    list = list.filter(d => d !== deviceDel);
    sensorList = sensorList.filter(e => e.device !== deviceDel);
    setSensorsData(sensorList);
    setRemDevices(list);
  }

  // form validation and calling updateDevice API
  const updateDevices = (e) => {
    e.preventDefault();

    // Getting all from inputs
    let email = e.target.signupEmail.value;
    let errDiv = document.getElementById("errDiv");

    //Error Messages
    let errMsg = [];

    //Regex
    let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


    // Validating all inputs
    if (email === "" || !regex.test(email)) {
      errMsg.push("Please enter the valid Email address");
    }

    if (newDevices.length === 0 && remDevices.length === 0) {
      errMsg.push("Please enter devices to be added or removed")
    }

    // If there error in user input then displaying those errors
    if (errMsg.length > 0) {
      errDiv.style.display = "block";
      errDiv.style.color = "red";
      errDiv.style.border = "1px solid red";
      errDiv.style.borderRadius = "10px";
      errDiv.style.marginBottom = "1rem";
      errDiv.style.padding = "0.5rem";

      let msgs = "";

      for (let i = 0; i < errMsg.length; i++) {
        msgs += errMsg[i] + "<br/>";
      }

      errDiv.innerHTML = msgs;
      window.scrollTo(0, 0);
    }
    else { // if no error message -> send updateDevice req to updateDevice API
      setLoadingAndMsg({
        loading: true,
        updateMsg: false,
        error: null
      });
      errDiv.style.display = "none";

      // setting data to be sent
      const data = {
        email: email,
        newDevices: newDevices,
        newSensors: sensorsData,
        remDevices: remDevices
      };



      // sending updateDevice request
      axios.post('https://cvme23aphj.execute-api.eu-central-1.amazonaws.com/updateDevice/', data)
        .then(res => {
          // if there is error 
          if (res.data.errorType) {
            setLoadingAndMsg({
              loading: false,
              updateMsg: false,
              error: res.data.errorMessage
            })

          } else {
            setSensorsData([])
            setCurrSensorsData([])
            setNewDevices([])
            setRemDevices([])
            // if updateDevice successfull -> display success message
            setLoadingAndMsg({
              loading: false,
              updateMsg: true,
              error: null
            })
          }

        })
        .catch(err => setLoadingAndMsg({
          loading: false,
          updateMsg: false,
          error: "Error Occured!"
        }))
      window.scrollTo(0, 0);
    }
  }

  function addAnotherUser() {
    setLoadingAndMsg({
      loading: false,
      updateMsg: false,
      error: null
    });
    setNewDevices([]);
    setRemDevices([]);
  }

  const { loading, updateMsg, error } = loadingAndMsg;

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            {/* Show loader while signing up */}
            {loading ?
              <div className="text-center" style={{ height: "70vh" }}>
                <Spinner className="justify-content-center" animation="grow" style={{ height: "10rem", width: "10rem", marginTop: "13rem", color: "#263B90" }} />
                <h6 style={{ padding: "1rem" }}>Loading...</h6>
              </div>
              :
              <Card style={{ borderRadius: "1rem" }}>
                <CardHeader>
                  <h3 className="">Update Devices</h3>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={(e) => updateDevices(e)}>
                    <Row>
                      <Col className="pl-md-1" md="8">
                        <FormGroup>
                          <div name="errDiv" id="errDiv" style={{ display: "none" }}></div>
                        </FormGroup>
                      </Col>
                    </Row>

                    {error ?
                      <Row>
                        <Col className="pl-md-1" md="8">
                          <FormGroup>
                            <div name="errDiv" id="errDiv" style={{ display: "block", fontSize: "1rem", fontWeight: '500', border: '1px solid red', borderRadius: '10px', padding: '0.5rem', color: 'red' }}>
                              {error}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      :
                      <div></div>
                    }

                    {updateMsg ?
                      <Row>
                        <Col className="pl-md-1" md="6">
                          <FormGroup>
                            <Alert color="success" style={{ fontSize: '1.1rem' }}>
                              <p>Devices Updated Successfully !</p>
                            </Alert>
                          </FormGroup>
                          <Row>
                            <Col className="pl-md-1 mt-4" md="6">
                              <FormGroup>
                                <Button id="addMoreUser" className="btn-fill" color="primary" onClick={() => addAnotherUser()}>
                                  Update Another Devices
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      : <>
                        <Row>
                          <Col className="pl-md-1" md="8">
                            <FormGroup>
                              <label htmlFor="exampleInputEmail1">
                                Email address
                              </label>
                              <Input placeholder="Enter Email" type="text" name="signupEmail" />
                            </FormGroup>
                          </Col>
                        </Row>

                        {/* Add Device */}
                        <Row>
                          <Col xs="8">
                            <h4 style={{ margin: '2rem auto', marginBottom: '1rem' }}>New Device to be added</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="3">
                            <label htmlFor="newDeviceName">Device Name</label>
                            <Input
                              type="text"
                              id="newDeviceName"
                              placeholder="Enter Device Name"
                              name="newDeviceName"
                            />
                          </Col>
                          <Col xs="3">
                            <label htmlFor="newDeviceId">Device ID</label>
                            <Input
                              type="text"
                              id="newDeviceId"
                              placeholder="Enter Device ID"
                              name="newDeviceId"
                            />
                          </Col>
                          <Col xs="2" style={{ marginTop: '-10px' }}>
                            <label>Select Sensor</label>
                            <DropdownButton id="select_sensor" variant="warning" title="Sensors">
                              {SENSORS.map(e => (
                                <Dropdown.Item onClick={() => handleSensor(e)}>{e}</Dropdown.Item>
                              ))}
                            </DropdownButton>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="8">
                            <ListGroup type="unstyled" style={{ background: 'inherit' }}>
                              {currSensorsData.map((item, i) => {
                                return (
                                  <ListGroupItem
                                    key={i}
                                    style={{
                                      background: 'inherit',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      border: 'none',
                                      padding: '0.5rem',
                                    }}
                                  >
                                    <span id={'currSensor-' + i}>{item}</span>
                                    <Button
                                      className="btn-fill"
                                      color="danger"
                                      onClick={() => handleRemoveSensor(item)}
                                    >
                                      Delete Sensor
                                    </Button>
                                  </ListGroupItem>
                                );
                              })}
                            </ListGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="2">
                            <Button
                              className="btn-fill"
                              color="success"
                              onClick={() => addNewItem()}
                            >
                              ADD
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="8">
                            <ListGroup type="unstyled" style={{ background: 'inherit' }}>
                              {newDevices.map((item, i) => {
                                return (
                                  <ListGroupItem
                                    key={i}
                                    style={{
                                      background: 'inherit',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                      border: '1px solid #2b3553',
                                      padding: '0.5rem 0.8rem',
                                      marginBottom: '12px',
                                    }}
                                  >
                                    <span id={'device-' + i}>{item}</span>
                                    {sensorsData
                                      .find(e => e.device === item)
                                      ?.arr?.map(s => (
                                        <span>{s}</span>
                                      ))}
                                    <Button
                                      className="btn-fill"
                                      color="danger"
                                      onClick={() => deleteNewItem(i)}
                                    >
                                      Delete
                                    </Button>
                                  </ListGroupItem>
                                );
                              })}
                            </ListGroup>
                          </Col>
                        </Row>

                        {/* <Row>
                          <Col xs="8">
                            <ListGroup type="unstyled" style={{ background: 'inherit' }}>
                              {newDevices.map((item, i) => {
                                return (
                                  <ListGroupItem key={i} style={{ background: 'inherit', display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: 'none', padding: '0.5rem' }}>
                                    <span id={"device-" + i}>{item}</span>
                                    <Button
                                      className="btn-fill"
                                      color="danger"
                                      onClick={() => deleteNewItem(i)}
                                    >
                                      Delete
                                    </Button>
                                  </ListGroupItem>
                                );
                              })}
                            </ListGroup>
                          </Col>
                        </Row> */}





                        {/* Remove Device */}
                        <Row>
                          <Col xs="8">
                            <h4 style={{ margin: '2rem auto', marginBottom: '1rem' }}>Devices to be Removed</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="3">
                            <label htmlFor="remDeviceName">Device Name</label>
                            <Input
                              type="text"
                              id="remDeviceName"
                              placeholder="Enter Device Name"
                              name="remDeviceName"
                            />
                          </Col>
                          <Col xs="3">
                            <label htmlFor="remDeviceId">Device ID</label>
                            <Input
                              type="text"
                              id="remDeviceId"
                              placeholder="Enter Device ID"
                              name="remDeviceId"
                            />
                          </Col>
                          <Col xs="2">
                            <Button
                              className="btn-fill"
                              color="success"
                              onClick={addRemItem}
                            >
                              ADD
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="8">
                            <ListGroup type="unstyled" style={{ background: 'inherit' }}>
                              {remDevices.map((item, i) => {
                                return (
                                  <ListGroupItem key={i} style={{ background: 'inherit', display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: 'none', padding: '0.5rem' }}>
                                    <span id={"device-" + i}>{item}</span>
                                    <Button
                                      className="btn-fill"
                                      color="danger"
                                      onClick={() => deleteRemItem(i)}
                                    >
                                      Delete
                                    </Button>
                                  </ListGroupItem>
                                );
                              })}
                            </ListGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="pl-md-1 mt-4" md="4">
                            <FormGroup>
                              <Button id="loginBtn" className="btn-fill" color="primary" type="submit">
                                Update Devices
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </>}
                  </Form>
                </CardBody>
              </Card>
            }
          </Col>

        </Row>
      </div>
    </>
  );
}

export default UpdateDevices;