import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from './context/context';

import AdminLayout from 'layouts/Admin/Admin.js';
import UserLayout from 'layouts/User/User';
import Login from './views/Login';
import ForgetPassword from 'views/ForgetPassword';
import ScrollToTop from './ScrollToTop';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/black-dashboard-react.scss';
import 'assets/demo/demo.css';
import 'assets/css/nucleo-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@vaadin/vaadin-date-time-picker';

import ThemeContextWrapper from './components/ThemeWrapper/ThemeWrapper';
import BackgroundColorWrapper from './components/BackgroundColorWrapper/BackgroundColorWrapper';

function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

ReactDOM.render(
  <Provider>
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <BrowserRouter>
          <div
            className="back-to-top d-flex align-items-center justify-content-center"
            onClick={scrollTop}
          >
            <span style={{fontSize: '25px', color: 'white'}}>&#8593;</span>
          </div>
          <ScrollToTop />
          <Switch>
            <Route path="/admin" render={props => <AdminLayout {...props} />} />
            <Route path="/user" render={props => <UserLayout {...props} />} />
            <Route path="/login" render={() => <Login />} />
            <Route path="/forgetPassword" render={() => <ForgetPassword />} />
            <Route exact path="/">
              <Route path="/user" render={props => <UserLayout {...props} />} />
              <Redirect from="/" to="/login" />
            </Route>
          </Switch>
        </BrowserRouter>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  </Provider>,
  document.getElementById('root')
);
