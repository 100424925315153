import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { UserContext } from "context/context";
import Spinner from 'react-bootstrap/Spinner'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";


function Devices() {
  const [devices, setDevices] = useState([]);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false)


  useEffect(async () => {
    setLoading(true);
    let tempDevices = await axios.post("https://5rtjwvf30c.execute-api.eu-central-1.amazonaws.com/fetchOneDevice/", { email: user.email });
    setDevices(tempDevices.data.responseDataa);
    setLoading(false);
  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Devices</CardTitle>
              </CardHeader>
              <CardBody>
                {loading ?
                  <div className="text-center">
                    <Spinner className="justify-content-center" animation="grow" style={{ height: "7rem", width: "7rem", marginTop: "3.5rem", color: "#263B90" }} />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                  :
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Index</th>
                        <th>Device Name</th>
                        <th>Device ID</th>
                        {/* <th>Map</th> */}
                        <th>Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        devices.map((device, index) => {
                          let deviceArr = device.split(":");
                          let deviceName = deviceArr[0].trim()
                          let deviceId = deviceArr[1].trim()
                          let map = `map/${device}`
                          let link = `dashboard/${device}`
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{deviceName}</td>
                              <td>{deviceId}</td>
                              {/* <td><Link to={map}>Map</Link></td> */}
                              <td><Link to={link}>Link</Link></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Devices;
