import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";
import { Card, CardHeader, CardBody, Row, Col, Alert } from "reactstrap";

import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";

import axios from "axios";

import { UserContext } from "context/context";

function Map() {
  // let { id } = useParams();

  // state
  const [loc, setLoc] = useState(null);
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: true,
    msg: null,
  });
  const [loadingInPin, setLoadingInPin] = useState(false);

  const { user } = useContext(UserContext);

  async function getOneDeviceLocation() {
    // if (id === ":id") {
    let tempDevices = await axios.post(
      "https://5rtjwvf30c.execute-api.eu-central-1.amazonaws.com/fetchOneDevice/",
      { email: user.email }
    );

    let devices = tempDevices.data.responseDataa;
    let device_Arr = [];

    for (let i = 0; i < devices.length; i++) {
      const device_id = devices[i];

      let tempDevices = await axios.post(
        "https://uxo9tmpbqk.execute-api.eu-central-1.amazonaws.com/fetchOneDeviceLocation/",
        { device_id: device_id.split(":")[1] }
      );

      tempDevices = tempDevices.data.responseDataa.Items;

      setLoadingAndMsg({
        // setting loadingAndMsg: to remove loading spinner and display error if any
        loading: true,
        msg: tempDevices.length === 0 ? "Loading..." : null,
      });

      if (tempDevices.length !== 0) {
        let position = {
          lat: tempDevices[0]?.payload?.latitude
            ? parseFloat(tempDevices[0].payload.latitude)
            : parseFloat(tempDevices[0].payload.GPS[1]),
          lng: tempDevices[0]?.payload?.longtitude
            ? parseFloat(tempDevices[0].payload.longtitude)
            : parseFloat(tempDevices[0].payload.GPS[0]),
        };

        let data = {
          enclTempRH: `${tempDevices[0]?.payload.sensor_SHT_1[0]}C/${tempDevices[0]?.payload.sensor_SHT_1[1]}%`,
          envTempRH: `${tempDevices[0]?.payload.sensor_SHT_2[0]}C/${tempDevices[0]?.payload.sensor_SHT_2[1]}%`,
          bhTempRH: `${tempDevices[0]?.payload.sensor_SHT_3[0]}C/${tempDevices[0]?.payload.sensor_SHT_3[1]}%`,
          weight1: `${(tempDevices[0]?.payload.sensor_HX711_1/1000).toFixed(2)}Kg`,
          weight2: `${(tempDevices[0]?.payload.sensor_HX711_2/1000).toFixed(2)}Kg`,
          battery: `${tempDevices[0]?.payload?.battery?.percentage} %`,
        };

        // let weightTemp = tempDevices[0].payload.sensor_HX711_2;

        // let data = {
        //   enclTempRH: "25C/65%",
        //   envTempRH: "25C/65%",
        //   bhTempRH: "25C/65%",
        //   // weight: "55.120Kg",
        //   weight: weightTemp+"Kg",
        //   battery: "60%",
        // };

        device_Arr.push({
          id: device_id,
          dateTime: (new Date(tempDevices[0].payload.datetime*1000)).toUTCString(),
          position: position,
          data: data,
        });
      }
    }
    setLoc(device_Arr);
    setLoadingAndMsg({
      // setting loadingAndMsg: to remove loading spinner and display error if any
      loading: false,
      msg: tempDevices.length === 0 ? "Loading..." : null,
    });
  }

  const onClickPinGetFreshData = async (id) => {
    let device_Arr = loc;
    setLoadingInPin(true);
    const device_id = id;

    let tempDevices = await axios.post(
      "https://uxo9tmpbqk.execute-api.eu-central-1.amazonaws.com/fetchOneDeviceLocation/",
      { device_id: device_id.split(":")[1], }
    );

    tempDevices = tempDevices.data.responseDataa.Items;

    if (tempDevices.length !== 0) {
      let position = {
        lat: tempDevices[0]?.payload?.latitude
          ? parseFloat(tempDevices[0].payload.latitude)
          : parseFloat(tempDevices[0].payload.GPS[1]),
        lng: tempDevices[0]?.payload?.longtitude
          ? parseFloat(tempDevices[0].payload.longtitude)
          : parseFloat(tempDevices[0].payload.GPS[0]),
      };

      let data = {
        enclTempRH: `${tempDevices[0]?.payload.sensor_SHT_1[0]}C/${tempDevices[0]?.payload.sensor_SHT_1[1]}%`,
        envTempRH: `${tempDevices[0]?.payload.sensor_SHT_2[0]}C/${tempDevices[0]?.payload.sensor_SHT_2[1]}%`,
        bhTempRH: `${tempDevices[0]?.payload.sensor_SHT_3[0]}C/${tempDevices[0]?.payload.sensor_SHT_3[1]}%`,
        weight1: `${(tempDevices[0]?.payload.sensor_HX711_1/1000).toFixed(2)}Kg`,
        weight2: `${(tempDevices[0]?.payload.sensor_HX711_2/1000).toFixed(2)}Kg`,
        battery: `${tempDevices[0]?.payload?.battery?.percentage} %`,
      };

      setLoadingInPin(false);
      device_Arr = device_Arr.filter((item) => item.id !== id);

      // let weightTemp = tempDevices[0].payload.sensor_HX711_2;

      // let data = {
      //   enclTempRH: "25C/65%",
      //   envTempRH: "25C/65%",
      //   bhTempRH: "25C/65%",
      //   // weight: "55.120Kg",
      //   weight: weightTemp+"Kg",
      //   battery: "60%",
      // };

      device_Arr.push({
        id: device_id,
        dateTime: (new Date(tempDevices[0].payload.datetime*1000)).toUTCString(),
        position: position,
        data: data,
      });

      setLoc(device_Arr);
    }
  };

  const history = useHistory();
  const mapOnClick = (deviceId, locationDetails) => {
    loc.map((l, index) => {
      if (
        l.position.lat === locationDetails.lat &&
        l.position.lng === locationDetails.lng
      ) {
        if (user.role == "1") {
          history.push(`/admin/dashboard/${deviceId}`);
        } else {
          history.push(`/user/dashboard/${deviceId}`);
        }
      }
    });
  }

  const analyticsOnClick=(deviceId, locationDetails)=>{
    loc.map((l, index) => {
      if (
        l.position.lat === locationDetails.lat &&
        l.position.lng === locationDetails.lng
      ) {
        if (user.role == "1") {
          history.push(`/admin/analytics/${deviceId}`);
        } else {
          history.push(`/user/analytics/${deviceId}`);
        }
      }
    });
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDtdf4dujAlo99cmadiPmFP03I4NtWVjpg",
  });

  const center = {
    lat: -3.745,
    lng: -38.523,
  };
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    getOneDeviceLocation();
  }, []);

  const { loading, msg } = loadingAndMsg;

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    map.setMapTypeId("satellite");

    const bounds = new window.google.maps.LatLngBounds();
    loc.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>Google Maps</CardHeader>
              <CardBody>
                <div
                  id="map"
                  className="map"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {!loading ? (
                    isLoaded ? (
                      <GoogleMap
                        clickableIcons={true}
                        options={{
                          streetViewControl: false,
                          draggable: true, // make map draggable
                          zoomControlOptions: { position: 9 },
                          keyboardShortcuts: false, // disable keyboard shortcuts
                          scaleControl: true, // allow scale controle
                          scrollwheel: true, // allow scroll wheel
                          zoomControl: true,
                          maxZoom: 10
                        }}
                        onLoad={handleOnLoad}
                        onClick={() => setActiveMarker(null)}
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                      >
                        {loc.map(({ id, position, data, dateTime }) => (
                          <Marker
                            key={id}
                            position={position}
                            onClick={() => handleActiveMarker(id)}
                          >
                            {activeMarker === id ? (
                              <InfoWindow
                                onCloseClick={() => setActiveMarker(null)}
                              >
                                {loadingInPin ? (
                                  <div className="text-center">
                                    <Spinner
                                      className="justify-content-center"
                                      animation="grow"
                                      style={{
                                        height: "7rem",
                                        width: "7rem",
                                        marginTop: "3.5rem",
                                        color: "#263B90",
                                      }}
                                    />
                                    <h6 style={{ padding: "1rem" }}>
                                      Loading...
                                    </h6>
                                  </div>
                                ) : (
                                  <div>
                                    <div>
                                      <text
                                        style={{
                                          color: "black",
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {id}
                                      </text>
                                    </div>

                                    <div>
                                      <text
                                        style={{
                                          color: "black",
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Time:
                                      </text>
                                      <text
                                        style={{ fontSize: 15, color: "black" }}
                                      >
                                        {dateTime}
                                      </text>
                                    </div>

                                    <hr/>

                                    <div>
                                      <text
                                        style={{
                                          color: "black",
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Encl. Temp/RH:
                                      </text>
                                      <text
                                        style={{ fontSize: 15, color: "black" }}
                                      >
                                        {data.enclTempRH}
                                      </text>
                                    </div>

                                    <div>
                                      <text
                                        style={{
                                          color: "black",
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Env. Temp/RH:
                                      </text>
                                      <text
                                        style={{ fontSize: 15, color: "black" }}
                                      >
                                        {data.envTempRH}
                                      </text>
                                    </div>

                                    <div>
                                      <text
                                        style={{
                                          color: "black",
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        BH. Temp/RH:
                                      </text>
                                      <text
                                        style={{ fontSize: 15, color: "black" }}
                                      >
                                        {data.bhTempRH}
                                      </text>
                                    </div>

                                    <div>
                                      <text
                                        style={{
                                          color: "black",
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Weight 1:
                                      </text>
                                      <text
                                        style={{ fontSize: 15, color: "black" }}
                                      >
                                        {data.weight1}
                                      </text>
                                    </div>

                                    <div>
                                      <text
                                        style={{
                                          color: "black",
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Weight 2:
                                      </text>
                                      <text
                                        style={{ fontSize: 15, color: "black" }}
                                      >
                                        {data.weight2}
                                      </text>
                                    </div>

                                    <div>
                                      <text
                                        style={{
                                          color: "black",
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Battery:
                                      </text>
                                      <text
                                        style={{ fontSize: 15, color: "black" }}
                                      >
                                        {data.battery}
                                      </text>
                                    </div>

                                    <button
                                      onClick={() => mapOnClick(id, position)}
                                      style={{
                                        backgroundColor: "#2184F8",
                                        color: "white",
                                        fontSize: "15px",
                                        padding: "5px 5px",
                                        borderRadius: "5px",
                                        border: "0px",
                                        margin: "5px 5px",
                                      }}
                                    >
                                      See Graph
                                    </button>
                                      
                                    <button
                                      onClick={() => analyticsOnClick(id, position)}
                                      style={{
                                        backgroundColor: "#2184F8",
                                        color: "white",
                                        fontSize: "15px",
                                        padding: "5px 5px",
                                        borderRadius: "5px",
                                        border: "0px",
                                        margin: "5px 5px",
                                      }}
                                    >
                                      See Analytics
                                    </button>
                                    

                                    <button
                                      onClick={() => onClickPinGetFreshData(id)}
                                      style={{
                                        backgroundColor: "#2184F8",
                                        color: "white",
                                        fontSize: "15px",
                                        padding: "5px 5px",
                                        borderRadius: "5px",
                                        border: "0px",
                                        margin: "5px 5px",
                                      }}
                                    >
                                      Refresh
                                    </button>
                                  </div>
                                )}
                              </InfoWindow>
                            ) : null}
                          </Marker>
                        ))}
                      </GoogleMap>
                    ) : (
                      <></>
                    )
                  ) : (
                    <div className="text-center">
                      <Spinner
                        className="justify-content-center"
                        animation="grow"
                        style={{
                          height: "7rem",
                          width: "7rem",
                          marginTop: "3.5rem",
                          color: "#263B90",
                        }}
                      />
                      <h6 style={{ padding: "1rem" }}>Loading...</h6>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Map;
