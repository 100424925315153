import React, { useState } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText,
} from "reactstrap";

function ForgetPassword() {
  // State
  const [email, setEmail] = useState("");
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: false,
    signUpMsg: false,
    error: null,
  });

  // sending OTP to given email address
  const sendOtp = (e) => {
    e.preventDefault();

    // Getting all from inputs
    let userEmail = e.target.email.value;
    let errDiv = document.getElementById("errDiv2");

    //Error Messages
    let errMsg = [];

    //Regex
    let regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // Validating all inputs
    if (userEmail === "" || !regex.test(userEmail)) {
      errMsg.push("Please enter the valid Email address");
    }

    // If there error in user input then displaying those errors
    if (errMsg.length > 0) {
      errDiv.style.display = "block";
      errDiv.style.color = "red";
      errDiv.style.border = "1px solid red";
      errDiv.style.borderRadius = "10px";
      errDiv.style.marginBottom = "1rem";
      errDiv.style.padding = "0.5rem";

      let msgs = "";

      for (let i = 0; i < errMsg.length; i++) {
        msgs += errMsg[i] + "<br/>";
      }

      errDiv.innerHTML = msgs;
      window.scrollTo(0, 0);
    } else {
      // if no error message -> send OTP request
      errDiv.style.display = "none";
      setLoadingAndMsg({
        loading: true,
        signUpMsg: false,
        error: null,
      });
      setEmail(userEmail);

      
      const data = {
        email: userEmail,
      };

      // sending OTP request
      axios
        .post("https://o08ldhzes0.execute-api.eu-central-1.amazonaws.com/forgotUserPassword/", data)
        .then((res) => {
          if (res.err || res.data.errorMessage) {
            setLoadingAndMsg({
              loading: false,
              signUpMsg: false,
              error: res.err ? res.err.message : res.data.errorMessage,
            });
            setEmail("");
          } else {
            setLoadingAndMsg({
              loading: false,
              signUpMsg: false,
              error: null,
            });
          }
          
        })
        .catch((err) =>
          setLoadingAndMsg({
            loading: false,
            signUpMsg: false,
            error: err,
          })
        );
      window.scrollTo(0, 0);
    }
  };

  // reset password
  const resetPassword = (e) => {
    e.preventDefault();

    // Getting all from inputs

    let vcode = e.target.vcode.value;
    let password = e.target.newPassword.value;
    let cPassword = e.target.newPasswordAgain.value;
    let errDiv = document.getElementById("errDiv");

    //Error Messages
    let errMsg = [];

    //Regex
    let passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // Validating all inputs

    if (vcode === "") {
      errMsg.push("Please enter the Verification code");
    }

    if (password === "") {
      errMsg.push("Please enter the Password");
    }

    if (!passwordRegex.test(password)) {
      errMsg.push("Password should be of minimum 8 characters");
      errMsg.push(
        "Password must contain a Number, an Uppercase, Lowercase and Special character"
      );
    }

    if (cPassword === "") {
      errMsg.push("Please enter the Confirm Password");
    }

    if (cPassword !== password) {
      errMsg.push("Password and Confirm Password Should Match!");
    }

    // If there error in user input then displaying those errors
    if (errMsg.length > 0) {
      errDiv.style.display = "block";
      errDiv.style.color = "red";
      errDiv.style.border = "1px solid red";
      errDiv.style.borderRadius = "10px";
      errDiv.style.marginBottom = "1rem";
      errDiv.style.padding = "0.5rem";

      let msgs = "";

      for (let i = 0; i < errMsg.length; i++) {
        msgs += errMsg[i] + "<br/>";
      }

      errDiv.innerHTML = msgs;
      window.scrollTo(0, 0);
    } else {
      // if no error message -> send reset password request
      setLoadingAndMsg({
        loading: true,
        signUpMsg: false,
        error: null,
      });
      errDiv.style.display = "none";

     
      const data = {
        vCode: vcode,
        email: email,
        newPassword: password,
      };

      // sending reset password request
      axios
        .post("https://816i4n9kg5.execute-api.eu-central-1.amazonaws.com/setNewPassword/", data)
        .then((res) => {
            // if there is error
          if (res.data.err) {
            setLoadingAndMsg({
              loading: false,
              signUpMsg: false,
              error: res.data.err.message,
            });
          } else {
            
            setLoadingAndMsg({
              loading: false,
              signUpMsg: true,
              error: null,
            });
          }
        })
        .catch((err) =>
          setLoadingAndMsg({
            loading: false,
            signUpMsg: false,
            error: "Error Occured",
          })
        );
      window.scrollTo(0, 0);
    }
  };

  const { loading, signUpMsg, error } = loadingAndMsg;
  if (email) {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            {/* Show loader while signing up */}
            {loading ? (
              <div className="text-center" style={{ height: "70vh" }}>
                <Spinner
                  className="justify-content-center"
                  animation="grow"
                  style={{
                    height: "10rem",
                    width: "10rem",
                    marginTop: "13rem",
                    color: "#263B90",
                  }}
                />
                <h6 style={{ padding: "1rem" }}>Loading...</h6>
              </div>
            ) : (
              <Card style={{ borderRadius: "1rem" }}>
                <CardHeader>
                  <h3 className="">Forget Password</h3>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={(e) => resetPassword(e)}>
                    <Row>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <div
                            name="errDiv"
                            id="errDiv"
                            style={{ display: "none" }}
                          ></div>
                        </FormGroup>
                      </Col>
                    </Row>

                    {error ? (
                      <Row>
                        <Col className="pl-md-1" md="4">
                          <FormGroup>
                            <div
                              name="errDiv"
                              id="errDiv"
                              style={{
                                display: "block",
                                fontSize: "1rem",
                                fontWeight: "500",
                                border: "1px solid red",
                                borderRadius: "10px",
                                padding: "0.5rem",
                                color: "red",
                              }}
                            >
                              {error}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : (
                      <div></div>
                    )}

                    {signUpMsg ? (
                      <Row>
                        <Col className="pl-md-1" md="6">
                          <FormGroup>
                            <div
                              name="errDiv"
                              id="errDiv"
                              style={{
                                display: "block",
                                fontSize: "1rem",
                                fontWeight: "500",
                                border: "1px solid #00D6B4",
                                borderRadius: "10px",
                                padding: "0.5rem",
                              }}
                            >
                              <p style={{ color: "#00D6B4" }}>
                                Password Changed Succesfully !{" "}
                              </p>
                              <p style={{ color: "#00D6B4" }}>
                                Please Go to Login Page
                              </p>
                            </div>
                          </FormGroup>
                          <Row>
                            <Col className="pl-md-1 mt-4" md="6">
                              <FormGroup>
                                <Link to="/login">
                                  <Button
                                    id="addMoreUser"
                                    className="btn-fill"
                                    color="primary"
                                  >
                                    Login
                                  </Button>
                                </Link>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <Row>
                          <Col className="pl-md-1" md="4">
                            <FormGroup>
                              <label htmlFor="vcode">Verification Code</label>
                              <Input
                                placeholder="Enter Verification Code"
                                type="text"
                                name="vcode"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-md-1" md="4">
                            <FormGroup>
                              <label htmlFor="password">New Password</label>
                              <Input
                                type="password"
                                placeholder="Enter New Password"
                                name="newPassword"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-md-1" md="4">
                            <FormGroup>
                              <label htmlFor="password">Confirm Password</label>
                              <Input
                                type="password"
                                placeholder="Enter Password Again"
                                name="newPasswordAgain"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-md-1 mt-4" md="4">
                            <FormGroup>
                              <Button
                                id="rstBtn"
                                style={{ width: "100%" }}
                                className="btn-fill"
                                color="primary"
                                type="submit"
                              >
                                Reset Password
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            {/* Show loader while signing up */}
            <Card style={{ borderRadius: "1rem" }}>
              <CardHeader>
                <h3 className="">Forget Password</h3>
              </CardHeader>
              <CardBody>
                <Form onSubmit={(e) => sendOtp(e)}>
                  <Row>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <div
                          name="errDiv2"
                          id="errDiv2"
                          style={{ display: "none" }}
                        ></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {error ? (
                    <Row>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <div
                            name="errDiv"
                            id="errDiv"
                            style={{
                              display: "block",
                              fontSize: "1rem",
                              fontWeight: "500",
                              border: "1px solid red",
                              borderRadius: "10px",
                              padding: "0.5rem",
                              color: "red",
                            }}
                          >
                            {error}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <div></div>
                  )}
                  <Row>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input
                          placeholder="Enter Email"
                          type="text"
                          name="email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-md-1 mt-4" md="4">
                      <FormGroup>
                        <Button
                          id="loginBtn"
                          className="btn-fill"
                          color="primary"
                          type="submit"
                        >
                          Send OTP
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ForgetPassword;
