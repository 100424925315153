import React, { useReducer, createContext } from 'react';
import {contextReducer} from './contextReducer';

const initialState = JSON.parse(localStorage.getItem('cookie')) || {};

export const UserContext = createContext(initialState);

export const Provider = ({ children }) => {
    // useReducer: complex version of useState() - used to maintain and update global state
    // transactions is state and dispatch used to pass action and state to "contextReducer" function
    const [ user, dispatch ] = useReducer(contextReducer, initialState);

    //Action Creators - dispatch the actions with type and payload (payload is some data passed)
    const addUser = (user) => dispatch({type: 'ADD_USER', payload: user});
    const removeUser = () => dispatch({type: 'REMOVE_USER'});
 
    return (
        // state and functions to modify state
        <UserContext.Provider value={{ user, addUser, removeUser}}>
            {children}
        </UserContext.Provider>
    );
}

