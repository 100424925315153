/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Map from "views/Map.js";
import TableList from "views/TableList.js";
import Devices from "views/Devices.js";
import RegisterUser from "views/RegisterUser";
import UpdateDevices from "views/UpdateDevices";

var routes = [
  {
    path: "/map",
    name: "Map",
    icon: "tim-icons icon-pin",
    component: Map,
    layout: "/admin",
  },
  {
    path: "/devices",
    name: "Devices",
    icon: "tim-icons icon-mobile",
    component: Devices,
    layout: "/admin",
  },
  {
    path: "/dashboard/:id",
    name: "Graphs",
    icon: "tim-icons icon-sound-wave",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/analytics/:id",
    name: "Statistics",
    icon: "tim-icons icon-chart-bar-32",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/register",
    name: "Register New User",
    icon: "tim-icons icon-single-02",
    component: RegisterUser,
    layout: "/admin",
  },
  {
    path: "/updateDevices",
    name: "Update Devices",
    icon: "tim-icons icon-pencil",
    component: UpdateDevices,
    layout: "/admin",
  },

  {
    path: "/devices",
    name: "Devices",
    icon: "tim-icons icon-mobile",
    component: Devices,
    layout: "/user",
  },
  {
    path: "/dashboard/:id",
    name: "Graphs",
    icon: "tim-icons icon-sound-wave",
    component: Dashboard,
    layout: "/user",
  },
  {
    path: "/map/:id",
    name: "Map",
    icon: "tim-icons icon-pin",
    component: Map,
    layout: "/user",
  },
  {
    path: "/tables",
    name: "Statistics",
    icon: "tim-icons icon-chart-bar-32",
    component: TableList,
    layout: "/user",
  },
];
export default routes;
