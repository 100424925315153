//reducer: takes old state and action: return new modified state

const contextReducer = (state, action) => {
    let newState;
    switch (action.type) {
        case 'ADD_USER':
            newState = {
                token: action.payload.token,
                role: action.payload.role,
                email: action.payload.email
            };
            localStorage.setItem('cookie', JSON.stringify(newState));
            return newState;

        case 'REMOVE_USER':
            newState = {};
            localStorage.removeItem('cookie');
            return newState;

        default:
            return state;
    }
};

module.exports = {contextReducer};
