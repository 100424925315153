import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router';
// nodejs library that concatenates classes
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { UserContext } from 'context/context';
import { Link } from 'react-router-dom';
import '@vaadin/vaadin-date-time-picker';
import moment from 'moment';

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Alert, Form, Button } from 'reactstrap';
import { SENSORS } from '../constants';

function Dashboard(props) {
  //States:
  // Graph Data
  const [data, setData] = useState([]);
  const [sensors, setSensonrs] = useState([]);

  //State to decide whether useEffect should be executed or not
  const [setIntervalVar, setSetIntervalVar] = useState(null);

  // State to show loading spinner while fetching data and showing error if exists
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: true,
    msg: null,
  });

  // State to set duration of data
  const [duration, setDuration] = useState({
    start: Date.now() - 24 * 60 * 60 * 1000,
    end: Date.now(),
  });

  //hooks
  const { user } = useContext(UserContext);
  let {id}  = useParams();

  // useEffect
  useEffect(() => {
    getData();
  }, [duration]);

  // GetData: fetches sensor data
  async function getData() {
    // Getting all the Devices if no id is passed
    let tempDevices = await axios.post(
      'https://5rtjwvf30c.execute-api.eu-central-1.amazonaws.com/fetchOneDevice/',
      { email: user.email }
    );
    if (id === ':id') {
      id = tempDevices.data.responseDataa[0];
    }

    // const tempSensors = tempDevices.data.responseDataa.find(e => e.device === id).arr;
    const tempSensors = tempDevices?.data?.sensor?.find(e => e.device === id)?.arr;
    if (tempSensors) {
      setSensonrs(tempSensors);
    } else {
      setSensonrs(SENSORS);
    }

    // fetching Sensor Data
    let temp = await axios.post(
      'https://qst03zmx07.execute-api.eu-central-1.amazonaws.com/fetchIotData/',
      { device_id: id.split(':')[1].trim(), start: duration.start, end: duration.end }
    );

    temp = temp.data.responseDataa.Items;

    // sorting based on time

    for (let i = 0; i < temp.length; i++) {
      // const newDateTime = temp[i]?.payload?.datetime;
      // if (newDateTime) {
      const date = moment(temp[i]?.timestamp).format('YYYY/MM/DD');
      const time = moment(temp[i]?.timestamp).format('HH:mm:ss');
      temp[i].payload.time = time;
      temp[i].payload.date = date;
      temp[i].timestamp = temp[i].payload.date + ' ' + temp[i].payload.time + ' UTC';
      // } else {
      //   var initial = temp[i].payload.date.split(/\//);
      //   temp[i].payload.date = [initial[2], initial[1], initial[0]].join('/');

      //   temp[i].payload.time = temp[i].payload.time
      //     .split('/')
      //     .map(e => {
      //       if (e.length === 1) return '0' + e;
      //       else return e;
      //     })
      //     .join(':');

      //   temp[i].timestamp = temp[i].payload.date + ' ' + temp[i].payload.time + ' UTC';
      // }
    }

    temp.sort(function (a, b) {
      // return new Date(a.payload.datetime) - new Date(b.payload.datetime);
      return new Date(a.timestamp) - new Date(b.timestamp);
    });

    setData(temp); // setting state: data to fetched data
    setLoadingAndMsg({
      // setting loadingAndMsg: to remove loading spinner and display error if any
      loading: false,
      msg:
        temp.length === 0 ? 'Sorry! Device is not active or Invalid Input in Date/Time 😔' : null,
    });

    // if (setIntervalVar === null) {
    //   getDataEveryMinute(); // calling getDataEveryMinute: set the interval and updates data every minute
    // }
  }

  // Variable Data: Every minute Data is updated
  function getDataEveryMinute() {
    let interval = setInterval(async () => {
      let temp = await axios.post(
        'https://qst03zmx07.execute-api.eu-central-1.amazonaws.com/fetchIotData/',
        { device_id: id.split(':')[1].trim(), start: Date.now() - 60 * 60 * 1000, end: Date.now() }
      );
      temp = temp.data.responseDataa.Items;

      for (let i = 0; i < temp.length; i++) {
        const newDateTime = temp[i]?.payload?.datetime;
        if (newDateTime) {
          const date = moment(newDateTime * 1000).format('YYYY/MM/DD');
          const time = moment(newDateTime * 1000).format('HH:mm:ss');
          temp[i].payload.time = time;
          temp[i].payload.date = date;
          temp[i].timestamp = temp[i].payload.date + ' ' + temp[i].payload.time + ' UTC';
        } else {
          var initial = temp[i].payload.date.split(/\//);
          temp[i].payload.date = [initial[2], initial[1], initial[0]].join('/');

          temp[i].payload.time = temp[i].payload.time
            .split('/')
            .map(e => {
              if (e.length === 1) return '0' + e;
              else return e;
            })
            .join(':');

          temp[i].timestamp = temp[i].payload.date + ' ' + temp[i].payload.time + ' UTC';
        }
      }

      temp.sort(function (a, b) {
        return new Date(a.timestamp) - new Date(b.timestamp);
      });

      setData(temp);
    }, 60000);
    setSetIntervalVar(interval);
  }

  // Get the duration from user input (on form submit) and change the duration state
  function setStartAndEnd(e) {
    e.preventDefault();

    // Taking user inputs
    let start = document.getElementById('startTime').value;
    let end = document.getElementById('endTime').value;

    if (start === '' && end === '') {
      return;
    }

    // Converting to EPOCH time format
    let startTime = new Date(start).getTime();
    let endTime = new Date(end).getTime();

    if (Number.isNaN(startTime)) {
      startTime = endTime - 24 * 60 * 60 * 1000;
    }

    if (Number.isNaN(endTime)) {
      endTime = startTime + 24 * 60 * 60 * 1000;
    }

    // If start time is earlier than end time
    if (startTime > endTime) {
      setLoadingAndMsg({
        loading: false,
        msg: 'Invalid duration!  Start time should be earlier than End Time 😕',
      });
    } else {
      clearInterval(setIntervalVar);
      setDuration({
        start: startTime,
        end: endTime,
      });
    }
  }

  // Functions to get the time, temp & hum, temp and weight from data

  // Time
  function getTime() {
    let arrTime = [];

    for (let i = 0; i < data.length; i++) {
      let tempData = data[i].payload.time;

      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }
      var initial = data[i].payload.date.split(/\//);
      var convertedTime = [initial[2], initial[1], initial[0]].join('/');

      tempData = tempData
        .split('/')
        .map(e => {
          if (e.length === 1) return '0' + e;
          else return e;
        })
        .join(':');
      tempData = convertedTime + ' - ' + tempData;

      arrTime.push(tempData);
    }
    return arrTime;
  }

  //Weight1
  //new: sensor_HX711_1
  //new: sensor_HX711_2

  //NTC_1-- > Temperature 1
  //NTC_2-- > Temperature 2
  //NTC_3-- > Temperature 3

  //SHT_1-- > Temperature + Humidity 1
  //SHT_2-- > Temperature + Humidity 2
  //SHT_3-- > Temperature + Humidity 3

  //NTC is an acronym for Negative Temperature Coefficient

  //Temp and Humidity 1
  //Old: sensor_DHT_1, sensor_DHT_2
  //New: sensor_SHT_1, sensor_SHT_2, sensor_SHT_3
  function getTempAndHum1() {
    let arrTemp = [];
    let arrHum = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      // const tempData = data[i].payload.sensor_SHT_1[0];
      // const humData = data[i].payload.sensor_SHT_1[1];

      const tempData = data[i]?.payload?.sensor_DHT_1
        ? data[i]?.payload?.sensor_DHT_1[0]
        : data[i].payload.sensor_SHT_1[0];
      const humData = data[i]?.payload?.sensor_DHT_1
        ? data[i]?.payload?.sensor_DHT_1[1]
        : data[i].payload.sensor_SHT_1[1];
      arrTemp.push(tempData);
      arrHum.push(humData);
    }
    return { arrTemp, arrHum };
  }
  //Temp and Humidity 2
  function getTempAndHum2() {
    let arrTemp = [];
    let arrHum = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }
      const tempData = data[i]?.payload?.sensor_DHT_2
        ? data[i]?.payload?.sensor_DHT_2[0]
        : data[i].payload.sensor_SHT_2[0];
      const humData = data[i]?.payload?.sensor_DHT_2
        ? data[i]?.payload?.sensor_DHT_2[1]
        : data[i].payload.sensor_SHT_2[1];
      arrTemp.push(tempData);
      arrHum.push(humData);
    }
    return { arrTemp, arrHum };
  }
  //Temp and Humidity 3
  function getTempAndHum3() {
    let arrTemp = [];
    let arrHum = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      if (data[i].payload.sensor_SHT_3) {
        const tempData = data[i]?.payload?.sensor_SHT_3[0];
        const humData = data[i]?.payload?.sensor_SHT_3[1];
        arrTemp.push(tempData);
        arrHum.push(humData);
      }
    }
    return { arrTemp, arrHum };
  }

  //Old: sensor_DS18B20_1, sensor_DS18B20_2
  //New: sensor_NTC_1, sensor_NTC_2, sensor_NTC_3
  //Temperature

  //OLD: DS18B20_1 & DS18B20_2
  function getTemperatureDS() {
    let arrTemp1 = [];
    let arrTemp2 = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      if (data[i].payload.sensor_DS18B20_1 && data[i].payload.sensor_DS18B20_2) {
        const tempData1 = data[i].payload.sensor_DS18B20_1[0];
        const tempData2 = data[i].payload.sensor_DS18B20_2[0];
        arrTemp1.push(tempData1);
        arrTemp2.push(tempData2);
      }
    }
    return { arrTemp1, arrTemp2 };
  }
  function getAllTemperature() {
    let arrTemp1 = [];
    let arrTemp2 = [];
    let arrTemp3 = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      const tempData1 = data[i]?.payload?.sensor_NTC_1;
      const tempData2 = data[i]?.payload?.sensor_NTC_2;
      const tempData3 = data[i]?.payload?.sensor_NTC_3;
      arrTemp1.push(tempData1);
      arrTemp2.push(tempData2);
      arrTemp3.push(tempData3);
    }
    return { arrTemp1, arrTemp2, arrTemp3 };
  }

  //Old: sensor_HX711
  //New: sensor_HX711_1, sensor_HX711_2
  //Weight
  function getWeight1() {
    let arrWeight = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }
      const tempData = data[i]?.payload?.sensor_HX711 ?? data[i]?.payload?.sensor_HX711_1;
      arrWeight.push(tempData);
    }
    return arrWeight;
  }
  function getWeight2() {
    let arrWeight = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }
      const tempData = data[i]?.payload?.sensor_HX711_2;
      arrWeight.push(tempData);
    }
    return arrWeight;
  }

  // Battery Voltage
  //Old: battery
  //New: battery
  function getBattery() {
    let arrBattery = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      const tempData = data[i].payload.battery?.voltage ?? data[i].payload.battery;
      arrBattery.push(tempData);
    }
    return arrBattery;
  }

  //OLD: signal_strenght - rssi
  function getRSSI() {
    let arrRssi = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }


      if (data[i].payload.signal_strength_rssi) {
        const tempData = data[i].payload.signal_strength_rssi[0];
        arrRssi.push(tempData);
      } else if (data[i].payload.signal_strength) {
        const tempData = data[i]?.payload?.signal_strength[0];
        arrRssi.push(tempData);
      } else {
        arrRssi.push("0");
      }


      // const tempData = data[i]?.payload?.signal_strength[0] ?? data[i]?.payload?.signal_strength_rssi[0];
      // arrRssi.push(tempData);

    }
    return arrRssi;
  }
  //OLD: signal_strenght - rsrp
  function getRSRP() {
    let arrRsrp = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }


      
      if (data[i].payload.signal_strength_rsrp) {
        const tempData = data[i].payload.signal_strength_rsrp[0];
        arrRsrp.push(tempData);
      } else if (data[i].payload.signal_strength) {
        const tempData = data[i]?.payload?.signal_strength[1];
        arrRsrp.push(tempData);
      } else {
        arrRsrp.push("0");
      }

      // if (data[i].payload.signal_strength_rsrp) {
      //   const tempData = data[i].payload.signal_strength_rsrp[0];
      //   const tempData = data[i].payload.signal_strength_rsrp[0] ?? data[i].payload.signal_strength[1];
      //   arrRsrp.push(tempData);
      // }
    }
    return arrRsrp;
  }
  //OLD: signal_strenght - sinr
  function getSINR() {
    let arrSinr = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      // if (data[i].payload.signal_strength_sinr) {
      //   const tempData = data[i].payload.signal_strength_sinr[0];
      //   // const tempData = data[i].payload.signal_strength_sinr[0] ?? data[i].payload.signal_strength[2];
      //   arrSinr.push(tempData);
      // }

      if (data[i].payload.signal_strength_sinr) {
        const tempData = data[i].payload.signal_strength_sinr[0];
        arrSinr.push(tempData);
      } else if (data[i].payload.signal_strength) {
        const tempData = data[i]?.payload?.signal_strength[2];
        arrSinr.push(tempData);
      } else {
        arrSinr.push("0");
      }
    }
    return arrSinr;
  }
  //OLD: signal_strenght - rsrq
  function getRSRQ() {
    let arrRsrq = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      // if (data[i].payload.signal_strength_rsrq) {
      //   const tempData = data[i].payload.signal_strength_rsrq[0];
      //   arrRsrq.push(tempData);
      // }

      if (data[i].payload.signal_strength_rsrq) {
        const tempData = data[i].payload.signal_strength_rsrq[0];
        arrRsrq.push(tempData);
      } else if (data[i].payload.signal_strength) {
        const tempData = data[i]?.payload?.signal_strength[3];
        arrRsrq.push(tempData);
      } else {
        arrRsrq.push("0");
      }
      

      // const tempData = data[i].payload.signal_strength_rsrq[0] ?? data[i].payload.signal_strength[3];
      // arrRsrq.push(tempData);
    }
    return arrRsrq;
  }

  // Chart Options
  let chart1_2_options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: true,
    },
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: 'nearest',
      intersect: 0,
      position: 'nearest',
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.0)',
            zeroLineColor: 'transparent',
          },
          ticks: {
            suggestedMin: -100,
            suggestedMax: 100,
            padding: 5,
            fontColor: '#9a9a9a',
          },
          display: true,
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Temperature(ºC) & Humidity(%)',
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.1)',
            zeroLineColor: 'transparent',
          },
          ticks: {
            padding: 20,
            fontColor: '#9a9a9a',
            text: 'time',
          },
          scaleLabel: {
            display: true,
            labelString: 'Time',
          },
        },
      ],
    },
  };

  // Temp & Humidity VS Time: Chart 1
  const TempHum1 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'Temperature(ºC)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum1().arrTemp,
          },
          {
            label: 'Humidity(%)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#d048b6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#d048b6',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#d048b6',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum1().arrHum,
          },
        ],
      };
    },
    options: chart1_2_options,
  };
  // Temp & Humidity VS Time: Chart 2
  const TempHum2 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'Temperature(ºC)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum2().arrTemp,
          },
          {
            label: 'Humidity(%)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#d048b6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#d048b6',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#d048b6',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum2().arrHum,
          },
        ],
      };
    },
    options: chart1_2_options,
  };
  // Temp & Humidity VS Time: Chart 3
  const TempHum3 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'Temperature(ºC)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum3().arrTemp,
          },
          {
            label: 'Humidity(%)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#d048b6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#d048b6',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#d048b6',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum3().arrHum,
          },
        ],
      };
    },
    options: chart1_2_options,
  };

  // Weight VS Time : Weight Chart 1
  const Weight1 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(66,134,121,0.15)');
      gradientStroke.addColorStop(0.4, 'rgba(66,134,121,0.0)'); //green colors
      gradientStroke.addColorStop(0, 'rgba(66,134,121,0)'); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'Weight(Kg)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#00d6b4',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#00d6b4',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#00d6b4',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getWeight1(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.0)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: -100,
              suggestedMax: 100,
              padding: 0.001,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Weight(Kg)',
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(0,242,195,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
      },
    },
  };
  // Weight VS Time : Weight Chart 2
  const Weight2 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(66,134,121,0.15)');
      gradientStroke.addColorStop(0.4, 'rgba(66,134,121,0.0)'); //green colors
      gradientStroke.addColorStop(0, 'rgba(66,134,121,0)'); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'Weight(Kg)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#00d6b4',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#00d6b4',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#00d6b4',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getWeight2(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.0)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: -100,
              suggestedMax: 100,
              padding: 0.001,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Weight(Kg)',
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(0,242,195,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
      },
    },
  };

  //OLD: Temperature 1 & 2 VS Time : Temperature Chart
  const chart5 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'DS18B20_1',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTemperatureDS().arrTemp1,
          },
          {
            label: 'DS18B20_2',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#d048b6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#d048b6',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#d048b6',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTemperatureDS().arrTemp2,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: true,
      },
      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.0)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: -100,
              suggestedMax: 100,
              padding: 5,
              fontColor: '#9a9a9a',
            },
            display: true,
            position: 'left',
            scaleLabel: {
              display: true,
              labelString: 'Temperature(ºC)',
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9a9a9a',
              text: 'time',
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
      },
    },
  };

  //New: Temperature 1 & 2 & 3 VS Time : Temperature Chart 1
  const TempNTC = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'NTC_1',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getAllTemperature().arrTemp1,
          },
          {
            label: 'NTC_2',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#d048b6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#d048b6',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#d048b6',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getAllTemperature().arrTemp2,
          },
          {
            label: 'NTC_3',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#00d6b4',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#00d6b4',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#00d6b4',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getAllTemperature().arrTemp3,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: true,
      },
      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.0)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: -100,
              suggestedMax: 100,
              padding: 5,
              fontColor: '#9a9a9a',
            },
            display: true,
            position: 'left',
            scaleLabel: {
              display: true,
              labelString: 'Temperature(ºC)',
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9a9a9a',
              text: 'time',
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
      },
    },
  };

  // Chart Data 4: Battery Voltage VS Time Only for admin users
  const chart4 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(66,134,121,0.15)');
      gradientStroke.addColorStop(0.4, 'rgba(66,134,121,0.0)'); //green colors
      gradientStroke.addColorStop(0, 'rgba(66,134,121,0)'); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'Battery Voltage(V)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#ab00d6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#ab00d6',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#ab00d6',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getBattery(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.0)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: -100,
              suggestedMax: 100,
              padding: 0.001,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Battery Voltage(V)',
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(0,242,195,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
      },
    },
  };

  // Chart Data 6: RSSI VS Time Only for admin users
  const chart6 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(66,134,121,0.15)');
      gradientStroke.addColorStop(0.4, 'rgba(66,134,121,0.0)'); //green colors
      gradientStroke.addColorStop(0, 'rgba(66,134,121,0)'); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'RSSI(dBm)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#ab00d6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#ab00d6',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#ab00d6',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getRSSI(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.0)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: -150,
              suggestedMax: 10,
              padding: 0.001,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Battery Voltage(V)',
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(0,242,195,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
      },
    },
  };

  // Chart Data 7: RSRP VS Time Only for admin users
  const chart7 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(66,134,121,0.15)');
      gradientStroke.addColorStop(0.4, 'rgba(66,134,121,0.0)'); //green colors
      gradientStroke.addColorStop(0, 'rgba(66,134,121,0)'); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'RSRP(dBm)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#00D6B4',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#00D6B4',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#00D6B4',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getRSRP(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.0)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: -100,
              suggestedMax: 100,
              padding: 0.001,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Battery Voltage(V)',
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(0,242,195,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
      },
    },
  };

  // Chart Data 8: SINR VS Time Only for admin users
  const chart8 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(66,134,121,0.15)');
      gradientStroke.addColorStop(0.4, 'rgba(66,134,121,0.0)'); //green colors
      gradientStroke.addColorStop(0, 'rgba(66,134,121,0)'); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'SINR(dB)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#1F8EF1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1F8EF1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1F8EF1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getSINR(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.0)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: -100,
              suggestedMax: 100,
              padding: 0.001,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Battery Voltage(V)',
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(0,242,195,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
      },
    },
  };

  // Chart Data 9: RSRQ VS Time Only for admin users
  const chart9 = {
    data: canvas => {
      let ctx = canvas.getContext('2d');

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(66,134,121,0.15)');
      gradientStroke.addColorStop(0.4, 'rgba(66,134,121,0.0)'); //green colors
      gradientStroke.addColorStop(0, 'rgba(66,134,121,0)'); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: 'RSRQ(dB)',
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#ab00d6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#ab00d6',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#ab00d6',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getRSRQ(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.0)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: -100,
              suggestedMax: 100,
              padding: 0.001,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Battery Voltage(V)',
            },
          },
        ],

        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: 'rgba(0,242,195,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9e9e9e',
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
      },
    },
  };

  const { loading, msg } = loadingAndMsg;
  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <h1>
              {id === ':id' ? (
                'Last Device You Viewed'
              ) : (
                <>
                  <strong style={{ color: 'rgb(0, 214, 180)' }}>{id.split(':')[0].trim()}: </strong>{' '}
                  {id.split(':')[1].trim()}
                </>
              )}
            </h1>
          </Col>
          <Col xs="12">
            <h4>
              To select another device visit <Link to="/">Devices</Link>
            </h4>
          </Col>
          <Col xs="12">
            <span className='mr-3' style={{ color: "#CDC9D1" }}>
              Sensor List:
            </span>
            {sensors?.map(e => (
              <span className='mr-3' style={{ color: '#BA54F5' }}>{e}</span>
            ))}
          </Col>
        </Row>

        {/* Duration: start and end time selection */}
        <Form onSubmit={event => setStartAndEnd(event)}>
          <Row>
            <Col sm="5">
              <vaadin-date-time-picker
                id="startTime"
                name="startTime"
                step="1800"
                label="From"
                style={{
                  maxWidth: '100%',
                  backgroundColor: '#b5d6ff',
                  borderRadius: '10px',
                  margin: '1rem',
                  padding: '0.6rem',
                }}
              ></vaadin-date-time-picker>
            </Col>
            <Col sm="5">
              <vaadin-date-time-picker
                id="endTime"
                name="endTime"
                step="1800"
                label="To"
                style={{
                  maxWidth: '100%',
                  backgroundColor: '#b5d6ff',
                  borderRadius: '10px',
                  margin: '1rem',
                  padding: '0.6rem',
                }}
              ></vaadin-date-time-picker>
            </Col>
            <Col sm="10">
              <Button style={{ width: '100%', margin: '1rem' }} type="submit" color="success">
                Apply
              </Button>
            </Col>
          </Row>
        </Form>
        {/* Chart 1: Temp and Humidity vs Time */}
        {(sensors.includes('DHT_1') || sensors.includes('SHT_1')) && (
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="12">
                      {/* <h5 className="card-category">Total Shipments</h5> */}
                      <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                        Temperature <strong style={{ color: 'cyan' }}>&</strong> Humidity{' '}
                        <strong style={{ color: 'cyan' }}>VS</strong> Time
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style={{ height: '50vh' }}>
                    {loading ? (
                      <div className="text-center">
                        <Spinner
                          className="justify-content-center"
                          animation="grow"
                          style={{
                            height: '7rem',
                            width: '7rem',
                            marginTop: '3.5rem',
                            color: '#263B90',
                          }}
                        />
                        <h6 style={{ padding: '1rem' }}>Loading...</h6>
                      </div>
                    ) : (
                      <>
                        {msg ? (
                          <Alert
                            style={{
                              textAlign: 'center',
                              fontSize: '1.1rem',
                              margin: 'auto',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              padding: '2rem',
                            }}
                            color="danger"
                          >
                            {msg}
                          </Alert>
                        ) : (
                          <Line data={TempHum1.data} options={TempHum1.options} />
                        )}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {/* Chart 2: Temp and Humidity vs time */}
        {(sensors.includes('DHT_2') || sensors.includes('SHT_2')) && (
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="12">
                      {/* <h5 className="card-category">Total Shipments</h5> */}
                      <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                        Temperature <strong style={{ color: '#D048B6' }}>&</strong> Humidity{' '}
                        <strong style={{ color: '#D048B6' }}>VS</strong> Time
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style={{ height: '50vh' }}>
                    {loading ? (
                      <div className="text-center">
                        <Spinner
                          className="justify-content-center"
                          animation="grow"
                          style={{
                            height: '7rem',
                            width: '7rem',
                            marginTop: '3.5rem',
                            color: '#D048B6',
                          }}
                        />
                        <h6 style={{ padding: '1rem' }}>Loading...</h6>
                      </div>
                    ) : (
                      <>
                        {msg ? (
                          <Alert
                            style={{
                              textAlign: 'center',
                              fontSize: '1.1rem',
                              margin: 'auto',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              padding: '2rem',
                            }}
                            color="danger"
                          >
                            {msg}
                          </Alert>
                        ) : (
                          <Line data={TempHum2['data']} options={TempHum2.options} />
                        )}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {/* Chart 3: Temp and Humidity vs time */}
        {sensors.includes('SHT_3') && (
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="12">
                      {/* <h5 className="card-category">Total Shipments</h5> */}
                      <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                        Temperature <strong style={{ color: '#D048B6' }}>&</strong> Humidity{' '}
                        <strong style={{ color: '#D048B6' }}>VS</strong> Time
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style={{ height: '50vh' }}>
                    {loading ? (
                      <div className="text-center">
                        <Spinner
                          className="justify-content-center"
                          animation="grow"
                          style={{
                            height: '7rem',
                            width: '7rem',
                            marginTop: '3.5rem',
                            color: '#D048B6',
                          }}
                        />
                        <h6 style={{ padding: '1rem' }}>Loading...</h6>
                      </div>
                    ) : (
                      <>
                        {msg ? (
                          <Alert
                            style={{
                              textAlign: 'center',
                              fontSize: '1.1rem',
                              margin: 'auto',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              padding: '2rem',
                            }}
                            color="danger"
                          >
                            {msg}
                          </Alert>
                        ) : (
                          <Line data={TempHum3['data']} options={TempHum3.options} />
                        )}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {/* Chart 4: Weight vs time */}
        {(sensors.includes('HX711') || sensors.includes('HX711_1')) && (
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="12">
                      {/* <h5 className="card-category">Total Shipments</h5> */}
                      <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                        Weight <strong style={{ color: '#00D6B4' }}>VS</strong> Time
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style={{ height: '50vh' }}>
                    {loading ? (
                      <div className="text-center">
                        <Spinner
                          className="justify-content-center"
                          animation="grow"
                          style={{
                            height: '7rem',
                            width: '7rem',
                            marginTop: '3.5rem',
                            color: '#00D6B4',
                          }}
                        />
                        <h6 style={{ padding: '1rem' }}>Loading...</h6>
                      </div>
                    ) : (
                      <>
                        {msg ? (
                          <Alert
                            style={{
                              textAlign: 'center',
                              fontSize: '1.1rem',
                              margin: 'auto',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              padding: '2rem',
                            }}
                            color="danger"
                          >
                            {msg}
                          </Alert>
                        ) : (
                          <Line data={Weight1['data']} options={Weight1.options} />
                        )}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {/* Chart 5: Weight vs time */}
        {sensors.includes('HX711_2') && (
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="12">
                      {/* <h5 className="card-category">Total Shipments</h5> */}
                      <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                        Weight <strong style={{ color: '#00D6B4' }}>VS</strong> Time
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style={{ height: '50vh' }}>
                    {loading ? (
                      <div className="text-center">
                        <Spinner
                          className="justify-content-center"
                          animation="grow"
                          style={{
                            height: '7rem',
                            width: '7rem',
                            marginTop: '3.5rem',
                            color: '#00D6B4',
                          }}
                        />
                        <h6 style={{ padding: '1rem' }}>Loading...</h6>
                      </div>
                    ) : (
                      <>
                        {msg ? (
                          <Alert
                            style={{
                              textAlign: 'center',
                              fontSize: '1.1rem',
                              margin: 'auto',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              padding: '2rem',
                            }}
                            color="danger"
                          >
                            {msg}
                          </Alert>
                        ) : (
                          <Line data={Weight2['data']} options={Weight2.options} />
                        )}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {/* Chart 6: Temp1 and Temp 2 vs time */}
        {sensors.includes('DS18B20_1') && sensors.includes('DS18B20_2') && (
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="12">
                      {/* <h5 className="card-category">Total Shipments</h5> */}
                      <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                        Temperature 1 <strong style={{ color: 'cyan' }}>&</strong> 2{' '}
                        <strong style={{ color: 'cyan' }}>VS</strong> Time
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style={{ height: '50vh' }}>
                    {loading ? (
                      <div className="text-center">
                        <Spinner
                          className="justify-content-center"
                          animation="grow"
                          style={{
                            height: '7rem',
                            width: '7rem',
                            marginTop: '3.5rem',
                            color: '#263B90',
                          }}
                        />
                        <h6 style={{ padding: '1rem' }}>Loading...</h6>
                      </div>
                    ) : (
                      <>
                        {msg ? (
                          <Alert
                            style={{
                              textAlign: 'center',
                              fontSize: '1.1rem',
                              margin: 'auto',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              padding: '2rem',
                            }}
                            color="danger"
                          >
                            {msg}
                          </Alert>
                        ) : (
                          <Line data={chart5['data']} options={chart5.options} />
                        )}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {/* Chart 6: Temp1 and Temp 2 and Temp 3 vs time */}
        {sensors.includes('NTC_1') && sensors.includes('NTC_2') && sensors.includes('NTC_3') && (
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="12">
                      {/* <h5 className="card-category">Total Shipments</h5> */}
                      <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                        Temperature 1 <strong style={{ color: 'cyan' }}>&</strong> 2{' '}
                        <strong style={{ color: 'cyan' }}>&</strong> 3{' '}
                        <strong style={{ color: 'cyan' }}>VS</strong> Time
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area" style={{ height: '50vh' }}>
                    {loading ? (
                      <div className="text-center">
                        <Spinner
                          className="justify-content-center"
                          animation="grow"
                          style={{
                            height: '7rem',
                            width: '7rem',
                            marginTop: '3.5rem',
                            color: '#263B90',
                          }}
                        />
                        <h6 style={{ padding: '1rem' }}>Loading...</h6>
                      </div>
                    ) : (
                      <>
                        {msg ? (
                          <Alert
                            style={{
                              textAlign: 'center',
                              fontSize: '1.1rem',
                              margin: 'auto',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              padding: '2rem',
                            }}
                            color="danger"
                          >
                            {msg}
                          </Alert>
                        ) : (
                          <Line data={TempNTC['data']} options={TempNTC.options} />
                        )}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {/* Charts only for Admin */}
        {user.role === '1' ? (
          <>
            {/* Chart 4: Battery Voltage vs time */}
            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="12">
                        {/* <h5 className="card-category">Total Shipments</h5> */}
                        <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                          Battery Voltage <strong style={{ color: '#ab00d6' }}>VS</strong> Time
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area" style={{ height: '50vh' }}>
                      {loading ? (
                        <div className="text-center">
                          <Spinner
                            className="justify-content-center"
                            animation="grow"
                            style={{
                              height: '7rem',
                              width: '7rem',
                              marginTop: '3.5rem',
                              color: '#00D6B4',
                            }}
                          />
                          <h6 style={{ padding: '1rem' }}>Loading...</h6>
                        </div>
                      ) : (
                        <>
                          {msg ? (
                            <Alert
                              style={{
                                textAlign: 'center',
                                fontSize: '1.1rem',
                                margin: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                padding: '2rem',
                              }}
                              color="danger"
                            >
                              {msg}
                            </Alert>
                          ) : (
                            <Line data={chart4['data']} options={chart4.options} />
                          )}
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Chart 6: RSSI vs time */}
            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="12">
                        {/* <h5 className="card-category">Total Shipments</h5> */}
                        <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                          RSSI <strong style={{ color: '#ab00d6' }}>VS</strong> Time
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area" style={{ height: '50vh' }}>
                      {loading ? (
                        <div className="text-center">
                          <Spinner
                            className="justify-content-center"
                            animation="grow"
                            style={{
                              height: '7rem',
                              width: '7rem',
                              marginTop: '3.5rem',
                              color: '#00D6B4',
                            }}
                          />
                          <h6 style={{ padding: '1rem' }}>Loading...</h6>
                        </div>
                      ) : (
                        <>
                          {msg ? (
                            <Alert
                              style={{
                                textAlign: 'center',
                                fontSize: '1.1rem',
                                margin: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                padding: '2rem',
                              }}
                              color="danger"
                            >
                              {msg}
                            </Alert>
                          ) : (
                            <Line data={chart6['data']} options={chart6.options} />
                          )}
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Chart 7: RSRP vs time */}
            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="12">
                        {/* <h5 className="card-category">Total Shipments</h5> */}
                        <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                          RSRP <strong style={{ color: '#00D6B4' }}>VS</strong> Time
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area" style={{ height: '50vh' }}>
                      {loading ? (
                        <div className="text-center">
                          <Spinner
                            className="justify-content-center"
                            animation="grow"
                            style={{
                              height: '7rem',
                              width: '7rem',
                              marginTop: '3.5rem',
                              color: '#00D6B4',
                            }}
                          />
                          <h6 style={{ padding: '1rem' }}>Loading...</h6>
                        </div>
                      ) : (
                        <>
                          {msg ? (
                            <Alert
                              style={{
                                textAlign: 'center',
                                fontSize: '1.1rem',
                                margin: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                padding: '2rem',
                              }}
                              color="danger"
                            >
                              {msg}
                            </Alert>
                          ) : (
                            <Line data={chart7['data']} options={chart7.options} />
                          )}
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Chart 8: SINR vs time */}
            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="12">
                        {/* <h5 className="card-category">Total Shipments</h5> */}
                        <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                          SINR <strong style={{ color: '#1F8EF1' }}>VS</strong> Time
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area" style={{ height: '50vh' }}>
                      {loading ? (
                        <div className="text-center">
                          <Spinner
                            className="justify-content-center"
                            animation="grow"
                            style={{
                              height: '7rem',
                              width: '7rem',
                              marginTop: '3.5rem',
                              color: '#00D6B4',
                            }}
                          />
                          <h6 style={{ padding: '1rem' }}>Loading...</h6>
                        </div>
                      ) : (
                        <>
                          {msg ? (
                            <Alert
                              style={{
                                textAlign: 'center',
                                fontSize: '1.1rem',
                                margin: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                padding: '2rem',
                              }}
                              color="danger"
                            >
                              {msg}
                            </Alert>
                          ) : (
                            <Line data={chart8['data']} options={chart8.options} />
                          )}
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Chart 9: RSRQ vs time */}
            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="12">
                        {/* <h5 className="card-category">Total Shipments</h5> */}
                        <CardTitle tag="h2" style={{ fontWeight: '500' }}>
                          RSRQ <strong style={{ color: '#ab00d6' }}>VS</strong> Time
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area" style={{ height: '50vh' }}>
                      {loading ? (
                        <div className="text-center">
                          <Spinner
                            className="justify-content-center"
                            animation="grow"
                            style={{
                              height: '7rem',
                              width: '7rem',
                              marginTop: '3.5rem',
                              color: '#00D6B4',
                            }}
                          />
                          <h6 style={{ padding: '1rem' }}>Loading...</h6>
                        </div>
                      ) : (
                        <>
                          {msg ? (
                            <Alert
                              style={{
                                textAlign: 'center',
                                fontSize: '1.1rem',
                                margin: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                padding: '2rem',
                              }}
                              color="danger"
                            >
                              {msg}
                            </Alert>
                          ) : (
                            <Line data={chart9['data']} options={chart9.options} />
                          )}
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // } else {
  //   return <Redirect to='login' />
  // }
}

export default Dashboard;
