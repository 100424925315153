import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Alert,
  Button,
} from "reactstrap";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import ReactSelect from "react-select";

function Tables() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: true,
    msg: null,
  });

  //Table 1 states
  const [arrTemp1, setArrTemp1] = useState([]);
  const [arrTemp2, setArrTemp2] = useState([]);
  const [arrTemp3, setArrTemp3] = useState([]);
  const [arrWeight1, setArrWeight1] = useState([]);
  const [arrWeight2, setArrWeight2] = useState([]);
  const [arrHumidity1, setArrHumidity1] = useState([]);
  const [arrHumidity2, setArrHumidity2] = useState([]);
  const [arrHumidity3, setArrHumidity3] = useState([]);

  //Table 2 states
  const [currentweight1, setCurrentweight1] = useState(0);
  const [currentweight2, setCurrentweight2] = useState(0);
  const [currWeight1at9, setCurrWeight1at9] = useState(0);
  const [currWeight2at9, setCurrWeight2at9] = useState(0);

  //Table 3 states
  const [weight1at9, setWeight1at9] = useState(0);
  const [weight1at21, setWeight1at21] = useState(0);
  const [weight2at9, setWeight2at9] = useState(0);
  const [weight2at21, setWeight2at21] = useState(0);

  const currentYear = new Date().getFullYear();
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");
  const [selectedDate, setSelectedDate] = useState({
    year: { value: currentYear, label: currentYear },
    month: { value: currentMonth, label: currentMonth },
  });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const handleYearChange = (selectedOption) => {
    setSelectedDate((prevState) => ({
      ...prevState,
      year: selectedOption,
    }));
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedDate((prevState) => ({
      ...prevState,
      month: selectedOption,
    }));
  };

  const years = Array.from(
    { length: 11 },
    (_, i) => new Date().getFullYear() - 10 + i
  );

  const months = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );

  useEffect(() => {
    if (id == ":id") {
      setLoadingAndMsg({
        loading: false,
        msg: "Sorry! Device is not active or Invalid Input in Date/Time 😔",
      });
    } else {
      getTable1Data();
      getTable2Data();
      getTable3Data();
    }
  }, []);

  useEffect(() => {
    if (id == ":id") {
      setLoadingAndMsg({
        loading: false,
        msg: "Sorry! Device is not active or Invalid Input in Date/Time 😔",
      });
    } else {
      getGraph1Data();
    }
  }, [selectedDate]);

  async function getTable1Data() {
    const endTimestamp = Date.now(); // Current time
    const startTimestamp = endTimestamp - 24 * 60 * 60 * 1000;

    setLoadingAndMsg({
      loading: true,
      msg: null,
    });

    try {
      let temp = await axios.post(
        "https://qst03zmx07.execute-api.eu-central-1.amazonaws.com/fetchIotData/",
        {
          device_id: id.split(":")[1].trim(),
          start: startTimestamp,
          end: endTimestamp,
        }
      );

      temp = temp.data.responseDataa.Items;

      // Sorting based on time
      for (let i = 0; i < temp.length; i++) {
        const date = moment(temp[i]?.timestamp).format("YYYY/MM/DD");
        const time = moment(temp[i]?.timestamp).format("HH:mm:ss");
        temp[i].payload.time = time;
        temp[i].payload.date = date;
        temp[i].timestamp =
          temp[i].payload.date + " " + temp[i].payload.time + " UTC";
      }

      temp.sort(function (a, b) {
        return new Date(a.timestamp) - new Date(b.timestamp);
      });

      setData(temp);
      setLoadingAndMsg({
        loading: false,
        msg: null,
      });
      updateTemperatureData(temp);
      updateWeightData(temp);
      updateHumidityData(temp);
    } catch (error) {
      setLoadingAndMsg({
        loading: false,
        msg: "An error occurred while fetching data. Please try again later.",
      });
    }
  }

  async function getTable2Data() {
    setLoadingAndMsg({
      loading: true,
      msg: null,
    });

    try {
      const currentDate = new Date();
      currentDate.setHours(9, 0, 0, 0);
      const startTimestamp = currentDate.getTime();
      const endTimestamp = Date.now();

      // Fetch data from API
      let response = await axios.post(
        "https://qst03zmx07.execute-api.eu-central-1.amazonaws.com/fetchIotData/",
        {
          device_id: id.split(":")[1].trim(),
          start: startTimestamp,
          end: endTimestamp,
        }
      );

      let data = response?.data?.responseDataa?.Items;

      data &&
        data.forEach((item) => {
          const measurementTimestamp = new Date(item.timestamp).getTime();
          const differenceInMillis = measurementTimestamp - startTimestamp;
          const differenceInMinutes = Math.floor(
            differenceInMillis / (1000 * 60)
          );
          item.differenceInMinutes = differenceInMinutes;
        });

      data &&
        data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      data && setCurrentweight1(data[data.length - 1].payload.sensor_HX711_1);
      data && setCurrentweight2(data[data.length - 1].payload.sensor_HX711_2);
      data && setCurrWeight1at9(data[0].payload.sensor_HX711_1);
      data && setCurrWeight2at9(data[0].payload.sensor_HX711_2);
    } catch (error) {
      throw error;
    }
  }

  async function getTable3Data() {
    const currentTimestamp = Date.now();
    const yesterdayTimestamp = currentTimestamp - 24 * 60 * 60 * 1000;
    const endTimestamp = new Date(yesterdayTimestamp);
    endTimestamp.setHours(21, 0, 0, 0);
    const startTimestamp = new Date(yesterdayTimestamp);
    startTimestamp.setHours(9, 0, 0, 0);

    setLoadingAndMsg({
      loading: true,
      msg: null,
    });

    try {
      let temp = await axios.post(
        "https://qst03zmx07.execute-api.eu-central-1.amazonaws.com/fetchIotData/",
        {
          device_id: id.split(":")[1].trim(),
          start: startTimestamp.getTime(),
          end: endTimestamp.getTime(),
        }
      );

      temp = temp.data.responseDataa.Items;

      // Sorting based on time
      for (let i = 0; i < temp.length; i++) {
        const date = moment(temp[i]?.timestamp).format("YYYY/MM/DD");
        const time = moment(temp[i]?.timestamp).format("HH:mm:ss");
        temp[i].payload.time = time;
        temp[i].payload.date = date;
        temp[i].timestamp =
          temp[i].payload.date + " " + temp[i].payload.time + " UTC";
      }

      temp.sort(function (a, b) {
        return new Date(a.timestamp) - new Date(b.timestamp);
      });
      setWeight1at9(temp[0].payload.sensor_HX711_1);
      setWeight1at21(temp[temp.length - 1].payload.sensor_HX711_1);
      setWeight2at9(temp[0].payload.sensor_HX711_2);
      setWeight2at21(temp[temp.length - 1].payload.sensor_HX711_2);
    } catch (error) {
      setLoadingAndMsg({
        loading: false,
        msg: "An error occurred while fetching data. Please try again later.",
      });
    }
  }

  async function getGraph1Data() {
    if (selectedDate.year && selectedDate.month) {
      try {
        setLoadingAndMsg({ loading: true, msg: "Loading..." });

        const response = await axios.post(
          "https://3cktddor11.execute-api.eu-central-1.amazonaws.com/prod/web-fetchMonthlyData",
          {
            year: String(selectedDate.year.value),
            month: selectedDate.month.value,
            device_id: id.split(":")[1].trim(),
          }
        );

        const { labels, weight1Diff, weight2Diff } = processData(
          response.data.body
        );

        setChartData({
          labels,
          datasets: [
            {
              label: "Weight 1 Difference",
              data: weight1Diff,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
            {
              label: "Weight 2 Difference",
              data: weight2Diff,
              fill: false,
              borderColor: "rgb(192, 75, 75)",
              tension: 0.1,
            },
          ],
        });

        setLoadingAndMsg({ loading: false, msg: null });
      } catch (error) {
        console.error("Error fetching monthly data:", error);
        setLoadingAndMsg({
          loading: false,
          msg: "Failed to fetch monthly data",
        });
      }
    } else {
      setLoadingAndMsg({ loading: false, msg: null });
      setChartData({
        labels: [],
        datasets: [],
      });
    }
  }

  const processData = (data) => {
    const labels = [];
    const weight1Diff = [];
    const weight2Diff = [];

    data.forEach((item) => {
      const { date, morning, evening } = item;
      labels.push(date);

      const morningWeight1 = morning.payload?.sensor_HX711_1 || 0;
      const eveningWeight1 = evening.payload?.sensor_HX711_1 || 0;
      const weight1Difference = eveningWeight1 - morningWeight1;
      weight1Diff.push(weight1Difference);

      const morningWeight2 = morning.payload?.sensor_HX711_2 || 0;
      const eveningWeight2 = evening.payload?.sensor_HX711_2 || 0;
      const weight2Difference = eveningWeight2 - morningWeight2;
      weight2Diff.push(weight2Difference);
    });

    return { labels, weight1Diff, weight2Diff };
  };

  // Function to update temperature data after fetching
  function updateTemperatureData(data) {
    let temp1 = [];
    let temp2 = [];
    let temp3 = [];

    // Extract temperature data from fetched data
    for (let i = 0; i < data.length; i++) {
      const tempData = data[i]?.payload;
      if (tempData) {
        temp1.push(tempData.sensor_NTC_1);
        temp2.push(tempData.sensor_NTC_2);
        temp3.push(tempData.sensor_NTC_3);
      }
    }

    // Update state with temperature data
    setArrTemp1(temp1);
    setArrTemp2(temp2);
    setArrTemp3(temp3);
  }

  function updateWeightData(data) {
    let arrWeight1 = [];
    let arrWeight2 = [];

    for (let i = 0; i < data.length; i++) {
      const tempData = data[i]?.payload;

      if (tempData) {
        arrWeight1.push(tempData.sensor_HX711_1);
        arrWeight2.push(tempData.sensor_HX711_2);
      }
    }
    setArrWeight1(arrWeight1);
    setArrWeight2(arrWeight2);
  }

  function updateHumidityData(data) {
    let arrHum1 = [];
    let arrHum2 = [];
    let arrHum3 = [];

    for (let i = 0; i < data.length; i++) {
      const tempData = data[i]?.payload;

      if (tempData) {
        arrHum1.push(
          tempData.sensor_DHT_1
            ? tempData.sensor_DHT_1[1]
            : tempData.sensor_SHT_1[1]
        );
        arrHum2.push(
          tempData.sensor_DHT_2
            ? tempData.sensor_DHT_2[1]
            : tempData.sensor_SHT_2[1]
        );
        arrHum3.push(
          tempData.sensor_DHT_3
            ? tempData.sensor_DHT_3[1]
            : tempData.sensor_SHT_3[1]
        );
      }
    }
    setArrHumidity1(arrHum1);
    setArrHumidity2(arrHum2);
    setArrHumidity3(arrHum3);
  }

  function calculateAverage(arr) {
    if (arr.length === 0) return 0;
    const sum = arr.reduce((total, current) => total + current, 0);
    return Number.parseFloat(sum / arr.length).toFixed(2);
  }

  const { loading, msg } = loadingAndMsg;

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Daily Analytics</CardTitle>
                <p>
                  Daily information about the Temperature, Humidity (x3) and
                  Weights (x1 or x2) from previous day to current day.
                </p>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <>
                    {msg ? (
                      <Alert
                        style={{
                          textAlign: "center",
                          fontSize: "1.1rem",
                          margin: "auto",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: "2rem",
                        }}
                        color="danger"
                      >
                        {msg}
                      </Alert>
                    ) : (
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Unit</th>
                            <th>Maximum</th>
                            <th>Minimun</th>
                            <th className="text-center">Average</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Temperature 1</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...arrTemp1).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...arrTemp1).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(arrTemp1)}
                            </td>
                          </tr>
                          <tr>
                            <td>Relative Humidity 1</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...arrHumidity1).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...arrHumidity1).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {" "}
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(arrHumidity1)}
                            </td>
                          </tr>
                          <tr>
                            <td>Temperature 2</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...arrTemp2).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...arrTemp2).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(arrTemp2)}
                            </td>
                          </tr>
                          <tr>
                            <td>Relative Humidity 2</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...arrHumidity2).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...arrHumidity2).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {" "}
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(arrHumidity2)}
                            </td>
                          </tr>
                          <tr>
                            <td>Temperature 3</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...arrTemp3).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...arrTemp3).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(arrTemp3)}
                            </td>
                          </tr>
                          <tr>
                            <td>Relative Humidity 3</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...arrHumidity3).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...arrHumidity3).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(arrHumidity3)}
                            </td>
                          </tr>
                          <tr>
                            <td>Weight 1</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...arrWeight1).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...arrWeight1).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(arrWeight1)}
                            </td>
                          </tr>
                          <tr>
                            <td>Weight 2</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...arrWeight2).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...arrWeight2).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(arrWeight2)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Weight Difference</CardTitle>
                <p>
                  weight difference of Weights (x1 or x2) from 9:00 to 21:00 of
                  previous day
                </p>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <>
                    {msg ? (
                      <Alert
                        style={{
                          textAlign: "center",
                          fontSize: "1.1rem",
                          margin: "auto",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: "2rem",
                        }}
                        color="danger"
                      >
                        {msg}
                      </Alert>
                    ) : (
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Unit</th>
                            <th>Weight at 9:00</th>
                            <th>Weight at 21:00</th>
                            <th>Differnce in weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Weight 1</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : weight1at9}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : weight1at21}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : weight1at21 - weight1at9}
                            </td>
                          </tr>
                          <tr>
                            <td>Weight 2</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : weight2at9}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : weight2at21}
                            </td>
                            <td className="text-center">
                              {" "}
                              {loading && msg == null
                                ? "loading..."
                                : weight2at21 - weight2at9}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Weight Difference</CardTitle>
                <p>
                  weight difference of Weights (x1 or x2) from current
                  measurement in weight to 09:00 of the same day
                </p>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <>
                    {msg ? (
                      <Alert
                        style={{
                          textAlign: "center",
                          fontSize: "1.1rem",
                          margin: "auto",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: "2rem",
                        }}
                        color="danger"
                      >
                        {msg}
                      </Alert>
                    ) : (
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Unit</th>
                            <th>Weight at 9:00</th>
                            <th>Current Weight</th>
                            <th>Differnce in weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Weight 1</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : currWeight1at9}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : currentweight1}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : currentweight1 - currWeight1at9}
                            </td>
                          </tr>
                          <tr>
                            <td>Weight 2</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : currWeight2at9}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : currentweight2}
                            </td>
                            <td className="text-center">
                              {" "}
                              {loading && msg == null
                                ? "loading..."
                                : currentweight2 - currWeight2at9}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Weight1 Difference</CardTitle>
                <p>
                  weight difference of weight1 and weight2 from 09:00 – 21:00
                  weight of every day.
                </p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="5">
                    <ReactSelect
                      value={selectedDate.year}
                      onChange={handleYearChange}
                      options={years.map((year) => ({
                        value: year,
                        label: year,
                      }))}
                      placeholder="Select Year"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          borderRadius: "0.25rem",
                          border: "1px solid #ced4da",
                          fontSize: "1rem",
                          lineHeight: 1.5,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#495057",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "0.25rem",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "transparent",
                          color: state.isSelected ? "#fff" : "#495057",
                          ":hover": {
                            backgroundColor: "#f8f9fa",
                            color: "#212529",
                          },
                        }),
                      }}
                    />
                  </Col>
                  <Col sm="5">
                    <ReactSelect
                      value={selectedDate.month}
                      onChange={handleMonthChange}
                      options={months.map((month) => ({
                        value: month,
                        label: month,
                      }))}
                      placeholder="Select Month"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          borderRadius: "0.25rem",
                          border: "1px solid #ced4da",
                          fontSize: "1rem",
                          lineHeight: 1.5,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#495057",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "0.25rem",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "transparent",
                          color: state.isSelected ? "#fff" : "#495057",
                          ":hover": {
                            backgroundColor: "#f8f9fa",
                            color: "#212529",
                          },
                        }),
                      }}
                    />
                  </Col>
                </Row>
                {loading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <Line
                    data={chartData}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                      },
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
